.pt-editable-text {
  display: inline-block;
  position: relative;
  cursor: text;
  max-width: 100%;
  vertical-align: top;
  white-space: nowrap; }
  .pt-editable-text::before {
    position: absolute;
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border-radius: 3px;
    content: "";
    transition: background-color 100ms cubic-bezier(0.4, 1, 0.75, 0.9), box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9); }
  .pt-editable-text:hover::before {
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15); }
  .pt-editable-text.pt-editable-editing::before {
    box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2);
    background-color: #ffffff; }
  .pt-editable-text.pt-disabled::before {
    box-shadow: none; }
  .pt-editable-text.pt-intent-primary .pt-editable-input,
  .pt-editable-text.pt-intent-primary .pt-editable-content {
    color: #137cbd; }
  .pt-editable-text.pt-intent-primary:hover::before {
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(19, 124, 189, 0.4); }
  .pt-editable-text.pt-intent-primary.pt-editable-editing::before {
    box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
  .pt-editable-text.pt-intent-success .pt-editable-input,
  .pt-editable-text.pt-intent-success .pt-editable-content {
    color: #0f9960; }
  .pt-editable-text.pt-intent-success:hover::before {
    box-shadow: 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), inset 0 0 0 1px rgba(15, 153, 96, 0.4); }
  .pt-editable-text.pt-intent-success.pt-editable-editing::before {
    box-shadow: 0 0 0 1px #0f9960, 0 0 0 3px rgba(15, 153, 96, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
  .pt-editable-text.pt-intent-warning .pt-editable-input,
  .pt-editable-text.pt-intent-warning .pt-editable-content {
    color: #d9822b; }
  .pt-editable-text.pt-intent-warning:hover::before {
    box-shadow: 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), inset 0 0 0 1px rgba(217, 130, 43, 0.4); }
  .pt-editable-text.pt-intent-warning.pt-editable-editing::before {
    box-shadow: 0 0 0 1px #d9822b, 0 0 0 3px rgba(217, 130, 43, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
  .pt-editable-text.pt-intent-danger .pt-editable-input,
  .pt-editable-text.pt-intent-danger .pt-editable-content {
    color: #db3737; }
  .pt-editable-text.pt-intent-danger:hover::before {
    box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px rgba(219, 55, 55, 0.4); }
  .pt-editable-text.pt-intent-danger.pt-editable-editing::before {
    box-shadow: 0 0 0 1px #db3737, 0 0 0 3px rgba(219, 55, 55, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
  .pt-dark .pt-editable-text:hover::before {
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(255, 255, 255, 0.15); }
  .pt-dark .pt-editable-text.pt-editable-editing::before {
    box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
    background-color: rgba(16, 22, 26, 0.3); }
  .pt-dark .pt-editable-text.pt-disabled::before {
    box-shadow: none; }
  .pt-dark .pt-editable-text.pt-intent-primary .pt-editable-content {
    color: #48aff0; }
  .pt-dark .pt-editable-text.pt-intent-primary:hover::before {
    box-shadow: 0 0 0 0 rgba(72, 175, 240, 0), 0 0 0 0 rgba(72, 175, 240, 0), inset 0 0 0 1px rgba(72, 175, 240, 0.4); }
  .pt-dark .pt-editable-text.pt-intent-primary.pt-editable-editing::before {
    box-shadow: 0 0 0 1px #48aff0, 0 0 0 3px rgba(72, 175, 240, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
  .pt-dark .pt-editable-text.pt-intent-success .pt-editable-content {
    color: #3dcc91; }
  .pt-dark .pt-editable-text.pt-intent-success:hover::before {
    box-shadow: 0 0 0 0 rgba(61, 204, 145, 0), 0 0 0 0 rgba(61, 204, 145, 0), inset 0 0 0 1px rgba(61, 204, 145, 0.4); }
  .pt-dark .pt-editable-text.pt-intent-success.pt-editable-editing::before {
    box-shadow: 0 0 0 1px #3dcc91, 0 0 0 3px rgba(61, 204, 145, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
  .pt-dark .pt-editable-text.pt-intent-warning .pt-editable-content {
    color: #ffb366; }
  .pt-dark .pt-editable-text.pt-intent-warning:hover::before {
    box-shadow: 0 0 0 0 rgba(255, 179, 102, 0), 0 0 0 0 rgba(255, 179, 102, 0), inset 0 0 0 1px rgba(255, 179, 102, 0.4); }
  .pt-dark .pt-editable-text.pt-intent-warning.pt-editable-editing::before {
    box-shadow: 0 0 0 1px #ffb366, 0 0 0 3px rgba(255, 179, 102, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
  .pt-dark .pt-editable-text.pt-intent-danger .pt-editable-content {
    color: #ff7373; }
  .pt-dark .pt-editable-text.pt-intent-danger:hover::before {
    box-shadow: 0 0 0 0 rgba(255, 115, 115, 0), 0 0 0 0 rgba(255, 115, 115, 0), inset 0 0 0 1px rgba(255, 115, 115, 0.4); }
  .pt-dark .pt-editable-text.pt-intent-danger.pt-editable-editing::before {
    box-shadow: 0 0 0 1px #ff7373, 0 0 0 3px rgba(255, 115, 115, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }

.pt-editable-input,
.pt-editable-content {
  display: inherit;
  position: relative;
  min-width: inherit;
  max-width: inherit;
  vertical-align: top;
  text-transform: inherit;
  letter-spacing: inherit;
  color: inherit;
  font: inherit;
  resize: none; }

.pt-editable-input {
  border: none;
  box-shadow: none;
  background: none;
  width: 100%;
  padding: 0;
  white-space: pre-wrap; }
  .pt-editable-input:focus {
    outline: none; }
  .pt-editable-input::-ms-clear {
    display: none; }

.pt-editable-content {
  overflow: hidden;
  padding-right: 2px;
  text-overflow: ellipsis;
  white-space: pre; }
  .pt-editable-editing > .pt-editable-content {
    position: absolute;
    left: 0;
    visibility: hidden; }
  .pt-editable-placeholder > .pt-editable-content {
    color: rgba(92, 112, 128, 0.5); }
    .pt-dark .pt-editable-placeholder > .pt-editable-content {
      color: rgba(191, 204, 214, 0.5); }

.Resizer {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    opacity: .2;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

.Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
}

.Resizer.horizontal {
    height: 5px;
    cursor: row-resize;
    width: 20px;
    
    align-self: center;
    border-top: solid 1px rgba(0, 0, 0, 0.5);
    border-bottom: solid 1px rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
    margin-right: 0.3em;
    width: 5px;
    cursor: col-resize;
    height: 20px;
    align-self: center;
    border-left: solid 1px rgba(0, 0, 0, 0.5);
    border-right: solid 1px rgba(0, 0, 0, 0.5);
}
